import { ApplyFriend } from '@/Api/Message';
import Storage from "@/util/Storage"
import {Toast} from "vant";


// 申请好友
export function IssueFriendApply(friendId:string|number,remark = "") {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    Toast.loading({ message:"申请中~~~",duration:0 })
    return Promise.resolve(ApplyFriend({ userId,token },{ friendId,remark }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
